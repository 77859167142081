<template>
  <v-form v-model="entityFormValid">
    <v-container>
      <v-layout>
        <v-flex md7>
          <v-text-field
            label="Nome ou Razão Social"
            v-model="formEntity.name"
            box
            :rules="[validationRules.required]"
            required
          />
        </v-flex>

        <v-flex md5>
          <v-text-field
            v-model="formEntity.cpf_cnpj"
            label="CNPJ ou CPF"
            box
            :mask="getMask(formEntity.cpf_cnpj)"
            :rules="getValidationRulesForCpfCnpj(formEntity.cpf_cnpj)"
            @blur="isEntityRegistred()"
            required
          />
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex md12>
          <v-text-field
            v-model="formEntity.postalCode"
            label="CEP"
            append-icon="mdi-magnify"
            box
            :mask="masks.postalcode"
            :rules="[validationRules.required, validationRules.postalcode]"
            @keyup.enter="searchAddressByCEP"
            @blur="searchAddressByCEP"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md8>
          <v-text-field
            v-model="formEntity.street"
            label="Logradouro"
            box
            :rules="[validationRules.required]"
            :loading="isFetchingAddress"
            :readonly="isFetchingAddress"
          />
        </v-flex>

        <v-flex md4>
          <v-text-field
            v-model="formEntity.number"
            :rules="[validationRules.required]"
            label="Número"
            box
            :loading="isFetchingAddress"
            :readonly="isFetchingAddress"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md6>
          <v-text-field
            v-model="formEntity.neighbourhood"
            label="Bairro"
            box
            :rules="[validationRules.required]"
            :loading="isFetchingAddress"
            :readonly="isFetchingAddress"
          />
        </v-flex>

        <v-flex md6>
          <v-text-field
            v-model="formEntity.address_details"
            label="Complemento"
            box
            :loading="isFetchingAddress"
            :readonly="isFetchingAddress"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex md8>
          <v-autocomplete
            v-model="formEntity.city"
            :items="cities"
            :rules="[validationRules.required]"
            :loading="isFetchingAddress"
            :readonly="isFetchingAddress"
            label="Município"
            no-data-text="Nenhum resultado encontrado"
            item-text="name"
            item-value="id"
            box
            return-object
            single-line
          />
        </v-flex>

        <v-flex md4>
          <v-autocomplete
            v-model="formEntity.state"
            :items="federalUnits"
            @change="fetchCities()"
            :rules="[validationRules.required]"
            :loading="isFetchingAddress"
            :readonly="isFetchingAddress"
            label="Estado"
            no-data-text="Nenhum resultado encontrado"
            item-text="acronym"
            item-value="id"
            box
            return-object
            single-line
          />
        </v-flex>
      </v-layout>

      <span class="subheading font-weight-bold">Perfil:</span>
      <v-layout row wrap class="px-2 profile">
        <v-checkbox
          color="primary"
          v-model="formEntity.profile"
          label="Gerador"
          value="generator"
        />
        <v-checkbox
          color="primary"
          v-model="formEntity.profile"
          label="Destinador"
          value="receiver"
        />
        <v-checkbox
          color="primary"
          v-model="formEntity.profile"
          label="Transportador"
          value="transporter"
        />
        <v-checkbox
          color="primary"
          v-model="formEntity.profile"
          label="Armazenador Temporário"
          value="temporary_storage"
        />
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import federalUnitService from '@/services/federal-unit-service';
import validationRules from '@/mixins/validation-rules';
import getAddressByCEP from '@/utils/getAddressByCEP';
import personService from '@/services/person-service';
import masks from '@/utils/masks';
import { mapActions } from 'vuex';
import { find } from 'lodash';

export default {
  name: 'create-entity-form',
  props: {
    formEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validationRules,
      getAddressByCEP,
      masks,
      dialog: false,
      loading: false,
      entityFormValid: false,
      entityAlreadyRegistred: false,
      isFetchingAddress: false,
      federalUnits: [],
      cities: [],
    };
  },
  async beforeMount() {
    this.federalUnits = await federalUnitService.fetchFederalUnits();
  },
  methods: {
    ...mapActions({ toggleSnackbar: 'application/toggleSnackbar' }),
    isEntityRegistred() {
      if (this.formEntity.cpf_cnpj !== '') {
        personService
          .searchForLink({ q: this.formEntity.cpf_cnpj })
          .then(({ data }) => {
            if (data.length >= 1) {
              this.entityAlreadyRegistred = true;

              this.toggleSnackbar({
                show: true,
                timeout: 6000,
                color: 'error',
                message: 'Uma entidade com esse CPF/CNPJ já está cadastrada no sistema.',
                messageIcon: 'mdi-alert-outline',
              });
            } else {
              this.entityAlreadyRegistred = false;
            }
          });
      }
    },
    async searchAddressByCEP() {
      if (this.formEntity.postalCode.length === 0) return;
      this.isFetchingAddress = true;

      try {
        const address = await this.getAddressByCEP(this.formEntity.postalCode);
        const federalUnitForEntityAddress = find(this.federalUnits, { acronym: address.state });
        this.cities = await federalUnitService.fetchCities(federalUnitForEntityAddress.id);
        const cityForEntityAddress = find(this.cities, { name: address.city });

        const entityAddress = {
          ...address,
          state: federalUnitForEntityAddress,
          city: cityForEntityAddress,
        };

        Object.assign(this.formEntity, entityAddress);
      } catch (error) {
        this.toggleSnackbar({
          show: true,
          timeout: 6000,
          color: 'error',
          message: error.message,
          messageIcon: 'mdi-alert-outline',
        });
      } finally {
        this.isFetchingAddress = false;
      }
    },
    getValidationRulesForCpfCnpj(cpfCnpj) {
      const rules = [];
      rules.push(cpfCnpj.length < 12 ? validationRules.cpf : validationRules.cnpj);
      return rules;
    },
    getMask(cpfCnpj) {
      return cpfCnpj.length < 12 ? '###.###.###-###' : '##.###.###/####-##';
    },
    async fetchCities() {
      this.cities = await federalUnitService.fetchCities(this.formEntity.state.id);
    },
    formIsValid() {
      this.$emit('formIsValid', (this.entityFormValid && !this.entityAlreadyRegistred));
    },
  },
  watch: {
    entityFormValid() {
      this.formIsValid();
    },
    entityAlreadyRegistred() {
      this.formIsValid();
    },
  },
};
</script>

<style>

</style>
