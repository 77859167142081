var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      model: {
        value: _vm.entityFormValid,
        callback: function ($$v) {
          _vm.entityFormValid = $$v
        },
        expression: "entityFormValid",
      },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { attrs: { md7: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Nome ou Razão Social",
                      box: "",
                      rules: [_vm.validationRules.required],
                      required: "",
                    },
                    model: {
                      value: _vm.formEntity.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "name", $$v)
                      },
                      expression: "formEntity.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { md5: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "CNPJ ou CPF",
                      box: "",
                      mask: _vm.getMask(_vm.formEntity.cpf_cnpj),
                      rules: _vm.getValidationRulesForCpfCnpj(
                        _vm.formEntity.cpf_cnpj
                      ),
                      required: "",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.isEntityRegistred()
                      },
                    },
                    model: {
                      value: _vm.formEntity.cpf_cnpj,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "cpf_cnpj", $$v)
                      },
                      expression: "formEntity.cpf_cnpj",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { attrs: { md12: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "CEP",
                      "append-icon": "mdi-magnify",
                      box: "",
                      mask: _vm.masks.postalcode,
                      rules: [
                        _vm.validationRules.required,
                        _vm.validationRules.postalcode,
                      ],
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchAddressByCEP.apply(null, arguments)
                      },
                      blur: _vm.searchAddressByCEP,
                    },
                    model: {
                      value: _vm.formEntity.postalCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "postalCode", $$v)
                      },
                      expression: "formEntity.postalCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { md8: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Logradouro",
                      box: "",
                      rules: [_vm.validationRules.required],
                      loading: _vm.isFetchingAddress,
                      readonly: _vm.isFetchingAddress,
                    },
                    model: {
                      value: _vm.formEntity.street,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "street", $$v)
                      },
                      expression: "formEntity.street",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { md4: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: [_vm.validationRules.required],
                      label: "Número",
                      box: "",
                      loading: _vm.isFetchingAddress,
                      readonly: _vm.isFetchingAddress,
                    },
                    model: {
                      value: _vm.formEntity.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "number", $$v)
                      },
                      expression: "formEntity.number",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { md6: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Bairro",
                      box: "",
                      rules: [_vm.validationRules.required],
                      loading: _vm.isFetchingAddress,
                      readonly: _vm.isFetchingAddress,
                    },
                    model: {
                      value: _vm.formEntity.neighbourhood,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "neighbourhood", $$v)
                      },
                      expression: "formEntity.neighbourhood",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { md6: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Complemento",
                      box: "",
                      loading: _vm.isFetchingAddress,
                      readonly: _vm.isFetchingAddress,
                    },
                    model: {
                      value: _vm.formEntity.address_details,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "address_details", $$v)
                      },
                      expression: "formEntity.address_details",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { md8: "" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.cities,
                      rules: [_vm.validationRules.required],
                      loading: _vm.isFetchingAddress,
                      readonly: _vm.isFetchingAddress,
                      label: "Município",
                      "no-data-text": "Nenhum resultado encontrado",
                      "item-text": "name",
                      "item-value": "id",
                      box: "",
                      "return-object": "",
                      "single-line": "",
                    },
                    model: {
                      value: _vm.formEntity.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "city", $$v)
                      },
                      expression: "formEntity.city",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { md4: "" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.federalUnits,
                      rules: [_vm.validationRules.required],
                      loading: _vm.isFetchingAddress,
                      readonly: _vm.isFetchingAddress,
                      label: "Estado",
                      "no-data-text": "Nenhum resultado encontrado",
                      "item-text": "acronym",
                      "item-value": "id",
                      box: "",
                      "return-object": "",
                      "single-line": "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.fetchCities()
                      },
                    },
                    model: {
                      value: _vm.formEntity.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.formEntity, "state", $$v)
                      },
                      expression: "formEntity.state",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "subheading font-weight-bold" }, [
            _vm._v("Perfil:"),
          ]),
          _c(
            "v-layout",
            { staticClass: "px-2 profile", attrs: { row: "", wrap: "" } },
            [
              _c("v-checkbox", {
                attrs: {
                  color: "primary",
                  label: "Gerador",
                  value: "generator",
                },
                model: {
                  value: _vm.formEntity.profile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formEntity, "profile", $$v)
                  },
                  expression: "formEntity.profile",
                },
              }),
              _c("v-checkbox", {
                attrs: {
                  color: "primary",
                  label: "Destinador",
                  value: "receiver",
                },
                model: {
                  value: _vm.formEntity.profile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formEntity, "profile", $$v)
                  },
                  expression: "formEntity.profile",
                },
              }),
              _c("v-checkbox", {
                attrs: {
                  color: "primary",
                  label: "Transportador",
                  value: "transporter",
                },
                model: {
                  value: _vm.formEntity.profile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formEntity, "profile", $$v)
                  },
                  expression: "formEntity.profile",
                },
              }),
              _c("v-checkbox", {
                attrs: {
                  color: "primary",
                  label: "Armazenador Temporário",
                  value: "temporary_storage",
                },
                model: {
                  value: _vm.formEntity.profile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formEntity, "profile", $$v)
                  },
                  expression: "formEntity.profile",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }